/* eslint-disable react/no-multi-comp */
/* eslint-disable import/no-default-export */

import { ApolloProvider, useQuery } from '@apollo/react-hooks'
import ApolloClient, { gql } from 'apollo-boost'
import React, { PureComponent, ReactNode, ReactElement } from 'react'
import fetch from 'isomorphic-unfetch'

import stageImagePath from '../assets/images/stage.jpg'
import { useTrackSreenView } from '../lib/tracking'

const client = new ApolloClient({
  fetch,
  uri: '/api/graphql',
})

/**
 * Users component.
 */
function Users(): ReactElement {
  useTrackSreenView('home screen')

  const { loading, error, data } = useQuery(gql`
    {
      books {
        data {
          title
          author
        }
      }
    }
  `)

  if (loading) {
    return <p>Loading...</p>
  }
  if (typeof error !== 'undefined') {
    return <p>Error</p>
  }

  return (
    <ul>
      {data.books.data.map(
        (
          { author, title }: { author: string; title: string },
          index: number
        ): ReactNode => (
          <li key={`name-${String(index)}`}>
            {title} - {author}
          </li>
        )
      )}
    </ul>
  )
}

class Home extends PureComponent<{ name?: string }> {
  public static getInitialProps({
    query: { name },
  }: {
    query: { name?: string }
  }): { name?: string } {
    return { name }
  }

  public render(): ReactNode {
    const { name: propsName } = this.props

    return (
      <>
        <ApolloProvider client={client}>
          <div>
            <h1>Drixsonic {process.env.NODE_ENV} 2</h1>
            <h2>Index {propsName}</h2>
            <Users />
            <img
              alt="stage"
              src={stageImagePath}
              style={{ marginRight: '8px', maxWidth: '100%' }}
            />
          </div>
        </ApolloProvider>
      </>
    )
  }
}

export default Home
